import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import MintUI from 'mint-ui'
import httpRequest from './service/httpRequest'
import 'mint-ui/lib/style.css'
// import Vconsole from 'vconsole'
/* eslint-disable no-new */
// new Vconsole()
// Vue.config.productionTip = false

Vue.prototype.$http = httpRequest
Vue.use(MintUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
