<template>
  <div class="home">
    <div class="content">
      <div class="summary">
        <img class="position" src="../assets/summary.png" alt="">
        <div class="detail">
          <div class="title">{{child_age}}个月宝宝发展概述</div>
          <div class="desc">{{summary}}</div>
        </div>
      </div>
      <img class="position" src="../assets/summary.png" alt="">
      <div class="ability">
        <div class="chart">
          <div style="width: 100%; height: 30rem" id="myChart"></div>
        </div>
        <div class="ability-list">
          <div v-for="item in mapData" :key="item.id" class="ability-item">
            <div class="title">
              <div class="text">{{item.title}}</div>
            </div>
            <div class="desc">
              {{item.desc}}
            </div>
          </div>
        </div>
      </div>
      <div class="tips">
        <img class="connect" src="../assets/link.png" alt="">
        <div class="title">温馨提示，若您家宝宝有以下情况，请及时咨询医院等专业机构：</div>
        <div class="tips-content">
          <pre>{{tips}}</pre>
        </div>
        <!-- <ul class="tips-list">
          <li v-for="item in 5" :key="item" class="tip-item">不能自如地走，经常摔倒; · 不能在成人帮助下爬台阶; </li>
        </ul> -->
      </div>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
export default {
  name: 'Home',
  data () {
    return {
      summary: '',
      tips: '',
      mapData: []
    }
  },
  created () {
    console.log(location.href)
    const url = location.href
    const newUrl = decodeURIComponent(url)
    let result = newUrl.split('?')[1].split('&')[0].split('=')[1]
    const child = newUrl.split('?')[1].split('&')[1].split('=')[1]
    result = result.split(',')
    // result.shift()
    console.log(result)
    const newResult = []
    result.forEach(item => {
      // const a = item.substring(2, item.length - 1)
      newResult.push(item)
    })
    console.log(newResult)
    this.child_age = child
    this.result = newResult
    this.getSummary()
    this.getTips()
    this.getChartData()
  },
  mounted () {
    const data = JSON.parse(JSON.stringify(this.result))
    let maxData = 0
    const seriesData = []
    data.forEach(item => {
      if (parseFloat(item) > maxData) {
        maxData = parseFloat(item)
      }
      seriesData.push(parseFloat(item))
    })
    const option = {
      tooltip: {},
      radar: {
        // shape: 'circle',
        name: {
          textStyle: {
            color: '#999',
            // backgroundColor: '#999',
            borderRadius: 3,
            padding: [0, 0]
          }
        },
        splitArea: { // 坐标轴在 grid 区域中的分隔区域，默认不显示。
          show: false,
          areaStyle: { // 分隔区域的样式设置。
            color: ['rgba(250,250,250,0.3)', 'rgba(200,200,200,0.3)']
            // 分隔区域颜色。分隔区域会按数组中颜色的顺序依次循环设置颜色。默认是一个深浅的间隔色。
          }
        },
        indicator: [
          { name: '大运动', max: maxData },
          { name: '精细动作', max: maxData },
          { name: '认知', max: maxData },
          { name: '语言', max: maxData },
          { name: '社会性', max: maxData }
        ]
      },
      series: [{
        type: 'radar',
        symbol: 'none',
        // areaStyle: {normal: {}},
        data: [
          {
            value: seriesData
          }
        ],
        lineStyle: {
          width: 0,
          opacity: 0
        },
        areaStyle: { // 单项区域填充样式
          normal: {
            color: 'rgba(0,191,255, 0.6)' // 填充的颜色。[ default: "#000" ]
          }
        }
      }]
    }
    const myChart = echarts.init(document.getElementById('myChart'))
    // 绘制图表
    myChart.setOption(option)
  },
  methods: {
    async getChartData () {
      console.log(11111111, this.result)
      const res = await this.$http('/api/assess-child-map', 'get', { child_age: this.child_age, result: [0, ...this.result] })
      for (var key in res) {
        if (res[key] === null) {
          delete res[key]
        } else {
          if (Number(key) === 1) {
            res[key].title = '大运动发展' + res[key].result
          } else if (Number(key) === 2) {
            res[key].title = '精细运动发展' + res[key].result
          } else if (Number(key) === 3) {
            res[key].title = '认知发展' + res[key].result
          } else if (Number(key) === 4) {
            res[key].title = '语言发展' + res[key].result
          } else if (Number(key) === 5) {
            res[key].title = '社会性发展' + res[key].result
          }
        }
      }
      this.mapData = res
    },
    async getSummary () {
      const res = await this.$http('/api/assess-child-content', 'get', { type: 1, child_age: this.child_age })
      this.summary = res.content
    },
    async getTips () {
      const res = await this.$http('/api/assess-child-content', 'get', { type: 2, child_age: this.child_age })
      this.tips = res.content
    }
  }
}
</script>
<style lang="less" scoped>
.home {
  padding: 1.5rem;
  background-image: url(../assets/bg.png);
  background-size: 100% auto;
  background-repeat: no-epeat;
  .position {
    width: 100%;
    height: auto;
  }
  .content {
    width: 100%;
    background-color: #FFF1D3;
    padding: 0 2rem 2rem;
    box-sizing: border-box;
    position: relative;
    .tips {
      background-color: #ffffff;
      margin-top: .5rem;
      box-sizing: border-box;
      padding: 3rem 1.5rem 1rem;
      position: relative;
      border-radius: .5rem;
      .connect {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(-55%);
        width: 80%;
        top: 0;
      }
      .title {
        font-size: 1.2rem;
        font-weight: bold;
      }
      .tips-content {
        margin-top: .5rem;
        pre {
          white-space: pre-wrap; /* css-3 */
          white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
          white-space: -pre-wrap; /* Opera 4-6 */
          white-space: -o-pre-wrap; /* Opera 7 */
          word-wrap: break-word; /* Internet Explorer 5.5+ */
          // font-size: 12px;
          // line-height: 22px;
          // color: #222222;
          font-family: 'Microsoft YaHei', Arial, sans-serif;
          text-align: justify;
          margin-bottom: 0;
        }
      }
      .tips-list {
        box-sizing: border-box;
        padding-left: 1.2rem;
        .tip-item {
          font-size: 1rem;
          line-height: 1.5rem;
        }
      }
    }
    .summary {
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      .position {
        width: 100%;
        height: auto;
      }
      .detail {
        position: absolute;
        box-sizing: border-box;
        padding: 1.2rem;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 3rem;
        color: #ffffff;
        .title {
          text-align: center;
          font-size: 1.4rem;
          font-weight: bold;
        }
        .desc {
          margin-top: .5rem;
          font-size: .95rem;
        }
      }
    }
    .ability {
      box-sizing: border-box;
      background-color: #ffffff;
      margin-top: -75px;
      padding-top: 60px;
      padding-bottom: 2.5rem;
      border-radius: .5rem;
      .ability-list {
        padding: 20px;
        margin-top: -3rem;
        .ability-item {
          margin-top: 1.5rem;
          &:first-child {
            margin-top: 0;
          }
          .title {
            font-size: 1.2rem;
            color: #16428B;
            font-weight: bold;
            display: flex;
            .text {
              position: relative;
              z-index: 1;
              &::after {
                display: block;
                content: "";
                position: absolute;
                width: 100%;
                height: 50%;
                background-color: #FFF9DC;
                bottom: 0;
                z-index: -1;
              }
            }
          }
          .desc {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
</style>
