import axios from 'axios'
import config from './config'
import { Toast, Indicator } from 'mint-ui'
const httpRequest = (api, method, params) => {
  const token = localStorage.getItem('token')
  return new Promise((resolve, reject) => {
    if (method === 'post' || method === 'POST') {
      axios({
        url: config.url + api,
        headers: {
          accept: 'application/json', // 自定义请求头信息
          Authorization: 'Bearer ' + token
        },
        method: method,
        data: params
      })
        .then(response => {
          resolve(response.data)
          Indicator.close()
        }, err => {
          Indicator.close()
          if (err.response) {
            if (err.response.status === 401) {
              window.location.href = location.origin + '/#/Login'
            } else {
              Toast({
                message: err.response.data.message,
                position: 'middle',
                duration: 1500
              })
            }
          }
          reject(err)
        })
        .catch(err => {
          Indicator.close()
          console.log(err)
          reject(err)
        })
    } else {
      axios.get(config.url + api, {
        header: {
          accept: 'application/json', // 自定义请求头信息
          Authorization: 'Bearer ' + token
        },
        params: params
      })
        .then(response => {
          resolve(response.data)
          Indicator.close()
        }, err => {
          Indicator.close()
          console.log(err.response)
          if (err.response) {
            if (err.response.status === 401) {
              window.location.href = location.origin + '/#/Login'
            } else {
              Toast({
                message: err.response.data.message,
                position: 'middle',
                duration: 1500
              })
            }
          }
          reject(err)
        })
        .catch(err => {
          Indicator.close()
          console.log(err)
          reject(err)
        })
    }
  })
}

export default httpRequest
